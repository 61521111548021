<template>
    <modal ref="modalMoverCarpeta" titulo="Mover de carpeta" icon="mover_cupon" no-aceptar adicional="Mover" @adicional="cambiarCarpeta">
        <div class="row mx-0 justify-center">
            <div class="col-10">
                <p class="text-general f-15 pl-3">
                    Carpeta
                </p>
                <el-select v-model="form.id_carpeta" placeholder="Select" class="w-100">
                    <el-option v-for="item in carpetas" :key="item.id" :label="item.nombre" :value="item.id" />
                </el-select>
            </div>
        </div>
    </modal>
</template>

<script>
import Cupones from '~/services/cupones/cupones'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            carpetas:[],
            form:{
                id_carpeta:null,
                id_cupon:null
            }
        }
    },
    computed:{
        ...mapGetters({
            cupon: 'cupones/cupones/datosDetalle'
        }),
        id_cupon(){
            return Number(this.$route.params.idCupon);
        },
    },
    methods: {
        toggle(){
            this.$refs.modalMoverCarpeta.toggle();
            this.listadoCarpetas()
        },
        async listadoCarpetas(){
            try {
                const id_cedis = 0
                const tipoVista = 1
                const {data} = await Cupones.listarCarpetas(id_cedis, tipoVista)
                this.carpetas = data.carpetas

                this.form.id_carpeta = this.cupon.id_carpeta

            } catch (e){
                this.error_catch(e)
            }
        },
        async cambiarCarpeta(){
            try {
                this.form.id_cupon = this.id_cupon
                const {data} = await Cupones.cambiarCarpeta(this.id_cupon, this.form)
                this.notificacion('Exito','Cambio de carpeta exitoso','success')
                this.$refs.modalMoverCarpeta.toggle();

            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
